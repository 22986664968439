export const environment = {
  production: false,
  CLIENT_ID:
    "69faa1a5ea45d102874d33a86407c9adzcore6b6d134eeec636504a3d4cb17d6d2987",
  ENV: "dsv",
  BASE_URL_CORE4YOU: "https://core4u-apim.tech4u.services/v1",
  BASE_URL: "https://api.sorrisobank.com.br/api",
  // BASE_URL: "http://192.168.0.34:3000/api",
  BASE_URL_CEP: "https://viacep.com.br/ws",

  //suporte@sorrisobank.com.br
};

import { Component, OnInit } from "@angular/core";
import { StorageService } from "../../services/storage.service";
import { Graduate } from "../../interfaces/graduate.interface";
import { GraduateService } from "../../services/graduate.service";
import { LoadingService } from "../../services/loading.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ModalConfirmacaoComponent } from "../modal-confirmacao/modal-confirmacao.component";

@Component({
  selector: "vex-switch-graduate",
  templateUrl: "./switch-graduate.component.html",
  styleUrls: ["./switch-graduate.component.scss"],
})
export class SwitchGraduateComponent implements OnInit {
  currentGraduateName: string;
  graduates: Graduate[];

  constructor(
    private storageService: StorageService,
    private graduateService: GraduateService,
    private loadingService: LoadingService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<SwitchGraduateComponent>,
    private dialog: MatDialog
  ) {}

  horizontalPosition: MatSnackBarHorizontalPosition = "end";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  formSwitch = this.fb.group({
    graduate: ["", Validators.required],
  });

  async ngOnInit() {
    await this.loadingService.showLoading(true);
    this.currentGraduateName = await this.storageService.get(
      "userGraduateName"
    );
    const currentGraduateId = await this.storageService.get("userGraduateId");
    const company = await this.storageService.get("userGraduateCompanyId");
    this.graduates = await this.graduateService.getGraduates(
      {
        filter: {
          $and: [{ companies: company }, { _id: { $ne: currentGraduateId } }],
        },
      },
      true
    );

    await this.loadingService.showLoading(false);
  }

  async cancel() {}

  async switchGraduate() {
    try {
      const dialogRef: any = await this.dialog.open(ModalConfirmacaoComponent, {
        data: {
          titulo: "Atenção",
          subtitulo: "O sistema irá recarregar. Deseja continuar?",
          role: "CONFIRMAR",
          labelBtnConfirmar: "Continuar",
        },
        width: "350px",
      });
      await dialogRef.afterClosed().toPromise();
      if (dialogRef._result) {
        const graduate = this.formSwitch.value.graduate;
        await this.storageService.set("userGraduateId", graduate._id);
        await this.storageService.set("userGraduateName", graduate.name);
        await this.storageService.set(
          "userGraduateStateName",
          graduate.stateName
        );
        await this.storageService.set(
          "userGraduateCityName",
          graduate.cityName
        );
        await this.storageService.set(
          "userGraduateCompanyId",
          graduate.company._id
        );
        this.snackbar.open(
          `Troca com sucesso. Licenciado atual: ${graduate.name}`,
          undefined,
          {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ["sucess-alert"],
            duration: 3000,
          }
        );
        this.dialogRef.close("success");
      }
    } catch (error) {
      this.snackbar.open("Ocorreu um erro na troca do licenciado", undefined, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ["error-alert"],
        duration: 3000,
      });
    }
  }
}

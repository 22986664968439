import { Component, OnInit } from "@angular/core";
import { PopoverRef } from "../popover/popover-ref";
import { Router } from "@angular/router";
import { StorageService } from "src/app/shared/services/storage.service";
import { ProfileEnum } from "src/app/shared/constantes/profile.enum";
import { MatDialog } from "@angular/material/dialog";
import { SwitchGraduateComponent } from "src/app/shared/components/switch-graduate/switch-graduate.component";

@Component({
  selector: "vex-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
})
export class UserMenuComponent implements OnInit {
  isSwitchtable: boolean = false;
  constructor(
    private readonly popoverRef: PopoverRef,
    private router: Router,
    private storageService: StorageService,
    private dialog: MatDialog,
  ) {}

  async ngOnInit() {
    const profile = await this.storageService.get('userProfile');
    this.isSwitchtable = profile === ProfileEnum.MASTER_STATE;

  }

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
  }

  async logout() {
    setTimeout(() => this.popoverRef.close(), 250);
    await this.storageService.remove("erp-token");
    await this.storageService.remove("erp-user");
    await this.router.navigate(["/login"]);
  }

  async openSwitchGraduate(){
    const dialogRef: any = await this.dialog.open(SwitchGraduateComponent, {
      width: "400px",
    });
    await dialogRef.afterClosed().toPromise();
    if (dialogRef._result === "success") {
     this.router.navigate([this.router.url]).then(()=>{
      window.location.reload();
     });
    }
  }
}

<mat-card>
  <mat-card-header>
    <mat-card-title>Troca de Licenciado</mat-card-title>
    <mat-card-subtitle>em <b>{{ currentGraduateName }}</b></mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="formSwitch">
      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6 pb-4">
        <mat-form-field class="flex-auto">
          <mat-label>Licenciado</mat-label>
          <mat-select formControlName="graduate">
            <mat-option *ngFor="let graduate of graduates" [value]="graduate">
              {{ graduate.code }} - {{ graduate.name }}
            </mat-option>
          </mat-select>
          <mat-icon
            class="ltr:mr-3 rtl:ml-3"
            matPrefix
            svgIcon="mat:person"
          ></mat-icon>
          <mat-hint
            style="color: red"
            *ngIf="
              formSwitch.controls['graduate']?.errors?.required &&
              formSwitch.controls['graduate'].touched
            "
          >
            Campo obrigatório</mat-hint
          >
        </mat-form-field>
      </div>
      <div class="actions flex items-center justify-end gap-2">
        <button
          (click)="cancel()"
          [disabled]="formSwitch.pristine"
          color="primary"
          mat-button
          type="button"
        >
          Cancelar
        </button>
        <button
          (click)="switchGraduate()"
          [disabled]="formSwitch.invalid"
          color="primary"
          mat-raised-button
        >
          Trocar
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>

import { Injectable } from "@angular/core";
import { Breadcrumb } from "../interfaces/breadcrumb.interface";

@Injectable({
  providedIn: "root",
})
export class BreadcrumbService {
  constructor() {}
  getBreadcrumbs(url: string): Breadcrumb[] {
    const arrayBreadCrumbs: Breadcrumb[] = new Array();

    const arraySplitRoute = url.split("/");
    if (!isNaN(Number(arraySplitRoute[arraySplitRoute.length - 1]))) {
      arraySplitRoute.splice(-1);
    }
    arraySplitRoute.forEach((r) => {
      if (r !== "") {
        switch (r) {
          case "analitico":
            arrayBreadCrumbs.push({ title: "Analítico", url: "analitico" });
            break;
          case "usuarios":
            arrayBreadCrumbs.push({ title: "Usuários", url: "usuarios" });
          case "empresas":
            arrayBreadCrumbs.push({ title: "Empresas", url: "empresas" });
            break;
          case "licenciados":
            arrayBreadCrumbs.push({ title: "Licenciados", url: "licenciados" });
            break;
          case "correntistas":
            arrayBreadCrumbs.push({
              title: "Correntistas",
              url: "correntistas",
            });
            break;
          case "investidores":
            arrayBreadCrumbs.push({
              title: "Investidores",
              url: "investidores",
            });
            break;
          case "contas":
            arrayBreadCrumbs.push({ title: "Contas", url: "" });
            break;
          case "criadas":
            arrayBreadCrumbs.push({ title: "Criadas", url: "contas/criadas" });
            break;
          case "em-analise":
            arrayBreadCrumbs.push({
              title: "Em Análise",
              url: "contas/em-analise",
            });
            break;
          case "nao-aprovadas":
            arrayBreadCrumbs.push({
              title: "Não Aprovadas",
              url: "contas/nao-aprovadas",
            });
            break;
          case "aprovadas":
            arrayBreadCrumbs.push({
              title: "Aprovadas",
              url: "contas/aprovadas",
            });
            break;
          case "clientes":
            arrayBreadCrumbs.push({
              title: "Clientes",
              url: "clientes",
            });
            break;
          case "produtos-emprestimos":
            arrayBreadCrumbs.push({
              title: "Produtos de Emprestimos",
              url: "",
            });
            break;
          case "categorias":
            arrayBreadCrumbs.push({
              title: "Categorias",
              url: "/produtos-emprestimos/categorias",
            });
            break;
          case "produtos":
            arrayBreadCrumbs.push({
              title: "Produtos",
              url: "/produtos-emprestimos/produtos",
            });
            break;
          case "configuracoes-conta":
            arrayBreadCrumbs.push({
              title: "Configurações de conta",
              url: "configuracoes-conta",
            });
            break;
          case "emprestimos":
            arrayBreadCrumbs.push({
              title: "Emprestimos",
              url: "",
            });
            break;
          case "solicitacoes":
            arrayBreadCrumbs.push({
              title: "Solicitações",
              url: "/emprestimos/solicitacoes",
            });
            break;
          case "documentacao-cliente":
            arrayBreadCrumbs.push({
              title: "Análise",
              url: "/emprestimos/documentacao-cliente",
            });
            break;
        }
      }
    });
    return arrayBreadCrumbs;
  }
}

<div class="vex-user-menu">
  <!-- <a (click)="close()"
     [routerLink]="['/apps/social']"
     class="vex-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:account_circle"></mat-icon>
    <div class="vex-user-menu-item__label">Your Profile</div>
  </a> -->

  <!-- <a (click)="close()"
     [routerLink]="['/']"
     class="vex-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:insights"></mat-icon>
    <div class="vex-user-menu-item__label">Analytics</div>
    <div class="vex-user-menu-item__badge">NEW</div>
  </a> -->

  <a
    (click)="close()"
    [routerLink]="['/configuracoes-conta']"
    class="vex-user-menu-item"
    matRipple
    matRippleColor="rgb(var(--color-primary), 0.1)"
  >
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:settings"
    ></mat-icon>
    <div class="vex-user-menu-item__label">Configurações da conta</div>
  </a>

  <div *ngIf="isSwitchtable" (click)="openSwitchGraduate();" class="vex-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:switch_account"></mat-icon>
    <div class="vex-user-menu-item__label">Trocar Licenciado</div>
  </div>

  <a
    (click)="logout()"
    class="vex-user-menu-item"
    matRipple
    matRippleColor="rgb(var(--color-primary), 0.1)"
  >
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:logout"
    ></mat-icon>
    <div class="vex-user-menu-item__label">Sair</div>
  </a>
</div>

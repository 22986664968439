import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { environment } from "src/environments/environment";
import { lastValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BaseService {
  constructor(
    protected http: HttpClient,
    protected storageService: StorageService
  ) {}

  /**
   * Sempre será passar id graduate do Storage se não existir no body
   */
  protected async postCreate(url: string, body: any) {
    if (!body.graduates) {
      const userGraduateId = await this.storageService.get("userGraduateId");
      const userGraduateName = await this.storageService.get(
        "userGraduateName"
      );
      const userGraduateStateName = await this.storageService.get(
        "userGraduateStateName"
      );
      const userGraduateCityName = await this.storageService.get(
        "userGraduateCityName"
      );
      body = {
        ...body,
        graduates: userGraduateId,
        graduatesName: userGraduateName,
        graduatesStateName: userGraduateStateName,
        graduatesCityName: userGraduateCityName,
      };
    }
    const transaction = this.http.post(`${environment.BASE_URL}${url}`, body);
    const result: any = await lastValueFrom(transaction);
    return result?.data;
  }
  protected async postUpdate(url: string, body: any) {
    const transaction = this.http.post(`${environment.BASE_URL}${url}`, body);
    const result: any = await lastValueFrom(transaction);
    return result?.data;
  }
  /**
   * Sempre será passado o id do gradute do Storage com base do filtro
   */
  protected async postFind(
    url: string,
    body: any,
    ignoreGraduate: boolean = false
  ) {
    const userGraduateId = await this.storageService.get("userGraduateId");
    const userProfile = await this.storageService.get("userProfile");
    if (userProfile !== "Master") {
      if (!ignoreGraduate) {
        body.filter = {
          ...body.filter,
          graduates: userGraduateId,
        };
      }
    }
    const transaction = this.http.post(`${environment.BASE_URL}${url}`, body);
    const result: any = await lastValueFrom(transaction);
    return result?.data;
  }
}

import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { LOCALE_ID, NgModule } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxExtendedPdfViewerService } from "ngx-extended-pdf-viewer";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { AuthInterceptor } from "src/app/shared/interceptors/auth.interceptor";
import { LoadingService } from "src/app/shared/services/loading.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { UsuarioService } from "src/app/shared/services/usuario.service";
import { VexModule } from "../@vex/vex.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CustomLayoutModule } from "./custom-layout/custom-layout.module";
import { AuthGuardService } from "./shared/services/auth-guard.service";

registerLocaleData(localePt);
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: "rgba(0,0,0,0.5)",
      backdropBorderRadius: "4px",
      primaryColour: "#ffffff",
      secondaryColour: "#ffffff",
      tertiaryColour: "#ffffff",
      fullScreenBackdrop: true,
    }),
    // Vex
    VexModule,
    CustomLayoutModule,
    MatSnackBarModule,
  ],
  providers: [
    StorageService,
    AuthGuardService,
    UsuarioService,
    LoadingService,
    NgxExtendedPdfViewerService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "pt-BR" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { Justification } from "../interfaces/justifications.interface";
import { BaseService } from "./base.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class CustomerService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected storageService: StorageService
  ) {
    super(http, storageService);
  }

  body = {
    schema: "customers",
    filter: {},
    select: {},
    sort: {
      customer: 1,
    },
    limit: 1000,
    skip: 0,
    populate: {},
    secondsCache: 1,
  };

  async create(data: any): Promise<any> {
    const transaction = this.http.post(
      `${environment.BASE_URL}/customers/register-customer`,
      data
    );
    const result: any = await lastValueFrom(transaction);
    return result?.data;
  }

  async update(data: any): Promise<any> {
    const transaction = this.http.post(
      `${environment.BASE_URL}/customers/update-customer`,
      data
    );
    const result: any = await lastValueFrom(transaction);
    return result?.data;
  }

  async countCustomers(body: any) {
    const bodyClone = { ...body };
    bodyClone.select = { _id: 1 };
    delete bodyClone.limit;
    delete bodyClone.skip;
    return await this.postFind("/customers/count-customers", bodyClone);
  }

  async getCustomersPaginator(body: any) {
    return await this.postFind("/customers/list-customers", body);
  }

  async getOneCustomer(body: any) {
    return await this.postFind("/customers/get-one-customer", body);
  }
  async getJustificaions() {
    const transaction = this.http.post(
      `${environment.BASE_URL}/customers/list-justification-customer`,
      this.body
    );
    const result: any = await lastValueFrom(transaction);
    return result?.data as Justification[];
  }

  async sendToIntegration(id) {
    try {
      const transaction = this.http.post(
        `${environment.BASE_URL}/transactions/onboarding/new-account`,
        { _id: id }
      );
      const result: any = await lastValueFrom(transaction);
      return result;
    } catch (e) {
      return { sucess: false, message: e.error.errors };
    }
  }

  async synchronizationAccounts() {
    try {
      const body = { ...this.body };
      body.filter = { imported: true };
      const transaction = this.http.post(
        `${environment.BASE_URL}/customers/synchronization-accounts`,
        body
      );
      const result: any = await lastValueFrom(transaction);
      return result;
    } catch (e) {
      return { sucess: false, message: e.error.errors };
    }
  }

  async listCustomerDocumentsFit(taxNumber) {
    try {
      const transaction = this.http.post(
        `${environment.BASE_URL}/customers/list-customer-documents-fit`,
        { taxNumber }
      );
      const result: any = await lastValueFrom(transaction);
      return result;
    } catch (e) {
      return { sucess: false, message: e.error.errors };
    }
  }

  async getCustomerExtractFit(taxNumber, startDate, endDate) {
    try {
      const transaction = this.http.post(
        `${environment.BASE_URL}/transactions/onboarding/get-account-entry`,
        {
          TaxNumber: taxNumber,
          StartDate: startDate,
          EndDate: endDate,
        }
      );
      const result: any = await lastValueFrom(transaction);
      return result;
    } catch (e) {
      return { sucess: false, message: e.error.errors };
    }
  }

  async createJustification(data: any): Promise<any> {
    const transaction = this.http.post(
      `${environment.BASE_URL}/customers/create-justification`,
      data
    );
    const result: any = await lastValueFrom(transaction);
    return result?.data;
  }

  async sendToAntiFraude(id) {
    try {
      const transaction = this.http.post(
        `${environment.BASE_URL}/transactions/onboarding/antiFraude`,
        { _id: id }
      );
      const result: any = await lastValueFrom(transaction);
      return result;
    } catch (e) {
      return { sucess: false, message: e.error.errors };
    }
  }

  async newAccount(data: any): Promise<any> {
    const transaction = this.http.post(
      `${environment.BASE_URL}/transactions/onboarding/new-account`,
      data
    );
    const result: any = await lastValueFrom(transaction);
    return result;
  }

  async getDocuments(data: any): Promise<any> {
    const transaction = this.http.post(
      `${environment.BASE_URL}/transactions/onboarding/get-documents`,
      data
    );
    const result: any = await lastValueFrom(transaction);
    return result?.data;
  }
}

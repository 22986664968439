import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { StorageService } from "./storage.service";
import { UsuarioService } from "./usuario.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private storageService: StorageService,
    private usuarioService: UsuarioService
  ) {}

  // @ts-ignore
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      const currentUser = await this.usuarioService.currentUser();
      if (!currentUser.success && !currentUser.data)
        await this.router.navigate(["login"]);

      const userProfile = await this.storageService.get("userProfile");
      const expectedRole = route.data.expectedRole;

      const profile = expectedRole.includes(userProfile);

      if (
        (await this.storageService.get("erp-token")) &&
        (await this.storageService.get("erp-user")) &&
        profile
      ) {
        return true;
      }
      await this.router.navigate(["error-unauthorized"]);
    } catch (error) {
      await this.router.navigate(["login"]);
    }
  }
}

import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fadeInRight400ms } from "../../../../@vex/animations/fade-in-right.animation";
import { fadeInUp400ms } from "../../../../@vex/animations/fade-in-up.animation";
import { scaleIn400ms } from "../../../../@vex/animations/scale-in.animation";
import { stagger80ms } from "../../../../@vex/animations/stagger.animation";

@Component({
  selector: "app-modal-confirmacao",
  templateUrl: "./modal-confirmacao.component.html",
  styleUrls: ["./modal-confirmacao.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [stagger80ms, fadeInUp400ms, scaleIn400ms, fadeInRight400ms],
})
export class ModalConfirmacaoComponent {
  constructor(
    private dialogRef: MatDialogRef<ModalConfirmacaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  cancelar() {
    this.dialogRef.close();
  }

  confirmar() {
    this.dialogRef.close(true);
  }
}
